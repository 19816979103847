import {
  arrayOf, bool, shape, string,
} from 'prop-types';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Login } from '../../components/common/auth/login';
import { BehindLogin, WithoutLogin } from '../../components/common/auth/states';
import { Layout, Details, Form } from '../../components/common/form';
import { Downloads } from '../../components/content/downloads';
import { MinimalHeading } from '../../components/headings/minimal';
import { Sitemeta } from '../../components/core/sitemeta';
import { useAuth } from '../../hooks/useAuth';
import { entryType, pictureFluidType, responseType } from '../../types';

export const query = graphql`
  query DownloadsPage {
    response: craftgql {
      downloads: globalSet(handle: "downloads") {
        ...on CraftGQL_downloads_GlobalSet {
          groups: downloadGroups {
            ...on CraftGQL_downloadGroups_group_BlockType {
              id
              headline
              description
              downloads {
                ...on CraftGQL_downloads_downloads_Entry {
                  title
                  slug
                  file: downloadFile {
                    url
                  }
                  preview: downloadPreview {
                    ...Picture
                    fluid_0: url(width: 100, mode: "crop", position: "") # 0.25
                    fluid_1: url(width: 200, mode: "crop", position: "") # 0.5
                    fluid_2: url(width: 400, mode: "crop", position: "") # max width
                    fluid_3: url(width: 600, mode: "crop", position: "") # 1.5
                    fluid_4: url(width: 800, mode: "crop", position: "") # 2
                  }
                  isPublic: downloadIsPublic
                  mailinglist: downloadMailinglist {
                    ...on CraftGQL_mailinglists_mailinglists_Entry {
                      headline: mailinglistHeadline
                      description: mailinglistDescription
                      endpoint: mailinglistEndpoint
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DownloadsPage = ({ data }) => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { groups } = data.response.downloads;

  return (
    <>
      <Sitemeta title={t('Downloads')} />
      <MinimalHeading headline={t('Downloads')} />
      <BehindLogin>
        {(groups || []).map(({
          id, headline, description, downloads,
        }) => (
          <Downloads
            key={id}
            id={id}
            headline={headline}
            description={description}
            downloads={downloads}
          />
        ))}
      </BehindLogin>
      <WithoutLogin>
        <Layout>
          <Details
            headline={t('Login required')}
            description={t('The list of downloads is behind a login. You need to sign in to see them.')}
          />
          <Form>
            <Login withoutDetails onLogin={login} />
          </Form>
        </Layout>
      </WithoutLogin>
    </>
  );
};

DownloadsPage.propTypes = {
  ...responseType({
    downloads: entryType({
      groups: arrayOf(shape({
        id: string.isRequired,
        headline: string.isRequired,
        description: string,
        downloads: arrayOf(shape({
          title: string.isRequired,
          slug: string.isRequired,
          file: arrayOf(shape({
            url: string.isRequired,
          })).isRequired,
          preview: arrayOf(pictureFluidType).isRequired,
          isPublic: bool.isRequired,
          mailinglist: arrayOf(shape({
            headline: string.isRequired,
            description: string.isRequired,
            endpoint: string.isRequired,
          })).isRequired,
        })).isRequired,
      })).isRequired,
    }).isRequired,
  }),
};

export default DownloadsPage;
